<template>
    <div>
        <headerTab></headerTab>
        <div class="confirm_content_c">
            <div class="userTitle_c">用户须知：请核对用户信息，该信息作为工分转让的收款账户，账户若存在异常请及时修改，且在转让前，请仔细阅<span @click="applyBtn">《申请书》</span>以及<span @click="ruleBtn">《居间协议》</span>，提交视为同意。</div>
            <div class="form_group_c">
                <div class="confirm_list_c">
                    <div class="title_c">户名：</div>
                    <div>{{tableData.user.union_info.bank.userName}}</div>
                </div>
                <div class="confirm_list_c">
                    <div class="title_c">联系方式：</div>
                    <div>{{tableData.user.union_info.phone}}</div>
                </div>
                <div class="confirm_list_c">
                    <div class="title_c">银行账户：</div>
                    <div>{{tableData.user.union_info.bank.number}}</div>
                </div>
                <div class="confirm_list_c">
                    <div class="title_c">银行详情：</div>
                    <div>{{tableData.user.union_info.bank.bank}}</div>
                </div>
                <div class="confirm_list_c">
                    <div class="title_c">收件地址：</div>
                    <textarea v-model="consignee_address" class="">
                    </textarea>
                </div>
                <div style="color:#FF3E46" class="userDetails_c" @click="userDetails" ><img src="../../assets/errorIcon2.png"/>账户异常，点击修改>></div>
            </div>
        </div>
        <div style="display:flex;margin-top:100px">
            <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box_c"></div>
            <div v-else class="select_box_c" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
            <div class="ruleBtn1_c">我已熟知与同意<span style="color:#409EFF" @click="applyBtn">《申请书》</span>和<span @click="ruleBtn" style="color:#409EFF">《居间协议》</span></div>
        </div>
        <div class="infoBtn" @click="submit">信息提交</div>
        <!-- 签字弹窗 -->
        <div class="signtureShow_c" v-if="signtureShow" @click="signtureShow">
            <div class="signtureShow_box_c">
                <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" :imgToken="imgToken" @cloneImg="cloneImg" @cloneBtn="cloneBtns" @imgUblod="imgUblod"></catSignature>
            </div>
        </div>
    </div>
</template>
<script>
import catSignature from "../../components/catSignature.vue";
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import { dealDetails_api , confimMessagte} from "@/api/deal";
import { Toast } from "vant";
// import {
  // shipments_api,getShipmentsList_api
// } from "@/api/admin";
// import { dealFromList_api, returnList_api,getConfig_api } from "@/api/deal";
export default {
  data() {
    return {
      showPicker: false,
      imgWidth: 645,
      signtureShow: false,
      img: "",
      imgConfig: "{ useCdnDomain: true }",
      iconShow: false,
      tableData:{
        user:{
          union_info:{}
        }
      },
      tableData1:{},
      consignee_address:'',
      imgToken:'',
      nameImg:'',
      flay:true
    };
  },
  components: {
    catSignature,
    headerTab
  },
  created() {
    document.title = "信息详情";
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
    this.getHome();
    this.getToken()
    if(localStorage.getItem("signature1")){
      this.nameImg =localStorage.getItem("signature1");
      this.iconShow = true
    }
  },
  methods: {
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
    },
     cloneBtns(){
      this.signtureShow = false
    },
    checkoutBtn(index) {
      console.log(index);
      if (index == 1) {
        this.signtureShow = true;
        // this.iconShow = true;
      } else {
        this.iconShow = false;
      }
    },
    cloneImg(){
      
    },
    submit(){
      if(!this.consignee_address){
        Toast({
            message: "请输入收货地址",
            icon: "success",
            duration: 1000
          });
          return false;
      }
      let data = {
        sales_info : {
          consignee_address : this.consignee_address,
          signature:this.nameImg
        }
      }
      if(this.flay){
        this.flay = false
      }
      confimMessagte(data, this.queryList.index).then(res=>{
        this.flay = true
        if(res.code==0){
          localStorage.removeItem("signature");
          localStorage.removeItem("signature1")
          Toast({
            message: "提交成功",
            icon: "success",
            duration: 1000
          });
          this.$router.go(-1)
        }
      })
    },
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          this.captchaConfig.mobile = this.tableData.user.union_info.phone;
          this.textPhone = this.tableData.user.union_info.phone.slice(0,3)+"****"+this.tableData.user.union_info.phone.slice(8,this.tableData.user.union_info.phone.length)
        }
      });
    },
    userDetails(){
      this.$router.push("./mine")
    },
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {},
    ruleBtn() {
      this.$router.push(`./contract?price=${this.tableData1.shares_price}&num=${this.tableData1.shares}&idx=${this.tableData1.trade.trade_id}`);
    },
    // 申请书
    applyBtn(){
      this.$router.push(`./applytext?id=${this.tableData1.trade.trade_id}&iconShow=${this.iconShow}`)
    },
    async getDetails(id) {
      let res = await apiCommon.SellingDeal_api(id);
      if (res.code == 0) {
        this.tableData1 = res.data;
        this.$forceUpdate();
      }
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      this.signtureShow = false;
      this.iconShow = true;
      this.nameImg = img;
      localStorage.setItem("signature1", img);
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    }
  }
};
</script>
<style>
.header_contract_c {
  display: flex;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-left: 41px
}
.header_contract_c div {
  
}
.header_contract_c img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1_c {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}
.messageTitle3_c .messageIcon_c {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle3_c {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}
.userTitle_c {
  color: #0f87ff;
  font-size: 24px;
  letter-spacing: 2.5px;
  line-height: 32px;
}
.confirm_content_c {
  width: 632px;
  margin: 136px auto 42px;
}
.userTitle_c span {
  color: #ff3e46;
}
.confirm_list_c {
  display: flex;
  font-size: 30px;
  color: #1c1b1b;
  margin-bottom: 30px;
}
.confirm_list_c .title_c {
  width: 150px;
  text-align: right;
}
.form_group_c {
  margin-top: 34px;
}
.userDetails_c {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  line-height:50px
}
.userDetails_c img{
  width:30px;height: 30px;
  margin: 50px 10px 0 0
}

.signtureShow_c {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box_c {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}

.ruleBtn1_c {
  color: #6D6D6D;
  margin-left: 20px;
  font-size: 25px;
}
.select_box_c {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 100px;
}
.office_submit_c {
  width: 206px;
  height: 68px;
  margin: 10px auto 0;
}
.office_submit_c img {
  width: 100%;
  height: 100%;
}
.infoBtn_c {
  width: 388px;
  height: 90px;
  background: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height:90px;
  font-size: 35px;
  color: #fff;
  margin: 30px auto 30px
}
</style>
